<template>
  <v-container fluid tag="section" style="height:90%;">
    <v-row no-gutters align="center" justify="center" class="no-mobile">
      <v-col cols="4">
        <div class="qr-text">Skenirajte QR kodo za ogled menija na vašem telefonu</div>
        <qrcode-vue class="qr-code" :value="qrcode_link" :size="200" level="H" id="qrcode"></qrcode-vue>
        <v-col cols="12" class="text-center" style="margin-top:15px">
          <v-btn color="primary mx-auto" @click="download">
            <v-icon>mdi-download</v-icon>Prenesite QR kodo za tisk
          </v-btn>
        </v-col>
      </v-col>
      <v-col class="text-left" style="padding-top:50px;" cols="3">
        <img :src="require('../../../assets/arrow-right.png')" width="200" />
      </v-col>
      <v-col class="text-center" cols="4">
        <div class="text-center" id="preview"></div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" no-gutters class="no-desktop" style="height:100%">
      <v-col cols="12">
        <div class="qr-text">Skenirajte QR kodo za ogled menija na vašem telefonu</div>
        <qrcode-vue class="qr-code" :value="qrcode_link" :size="200" level="H" id="qrcode"></qrcode-vue>
        <v-col cols="12" style="text-align:center;">
          <v-btn
            :href="'/meni/' + $store.state.authUser.link"
            color="primary"
            style="margin: auto;"
          >
            <v-icon>mdi-silverware</v-icon>Prikaži meni
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="primary mx-auto" @click="download">
            <v-icon>mdi-download</v-icon>Prenesite QR kodo za tisk
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <!--<v-col cols="12" style="text-align:center;">
      <v-btn :href="'/meni/' + $store.state.authUser.link" color="primary" style="margin: auto;">
        <v-icon>mdi-silverware</v-icon>Prikaži meni
      </v-btn>
    </v-col>
    <v-col cols="12" style="text-align:center;">
      <v-btn color="primary" style="margin: auto;" @click="download">
        <v-icon>mdi-download</v-icon>Prenesi QR kodo
      </v-btn>
    </v-col>-->
  </v-container>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  computed: {
    qrcode_link() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        "/meni/" +
        this.$store.state.authUser.link
      );
    },
  },
  mounted() {
    bioMp(document.getElementById("preview"), {
      url: "/meni/" + this.$store.state.authUser.link,
      view: "front",
      image: require("../../../assets/iphone6_front_white.png"),
      scale: 0.6,
    });
  },
  components: {
    QrcodeVue,
  },
  methods: {
    download() {
      let canvas = document.getElementById("qrcode").firstChild;
      var link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    },
  },
};
</script>
<style scoped>
.qr-text {
  text-align: center;
  width: 200px;
  margin: auto;
  padding-bottom: 15px;
}
.qr-code {
  margin: auto;
  width: 200px;
  height: 200px;
}

@media (min-width: 768px) {
  .no-desktop {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .no-mobile {
    display: none !important;
  }
}
</style>
